import { render, staticRenderFns } from "./NewAuth.vue?vue&type=template&id=45f7ee59&scoped=true&"
import script from "./NewAuth.vue?vue&type=script&lang=js&"
export * from "./NewAuth.vue?vue&type=script&lang=js&"
import style0 from "./NewAuth.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NewAuth.vue?vue&type=style&index=1&id=45f7ee59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f7ee59",
  null
  
)

export default component.exports