<template>
  <div class="d-flex justify-content-between align-items-center header px-5">
    <img src="/media/custom/orbi-bds-horizontal.png" alt="">
    <div class="languages-wrapper">
      <span
        v-for="language in languages"
        :key="language.lang"
        class="pl-5 cursor-pointer"
        :class="{'font-weight-bolder text-light-green': language.lang === activeLanguage}"
        @click="changeLanguage(language.lang)"
      >
        {{ language.title }}
      </span>
    </div>
  </div>
</template>

<script>
import i18nService from '@/core/services/i18n.service.js';

export default {
  data: () => ({
    languages: i18nService.languages,
    activeLanguage: i18nService.getActiveLanguage(),
  }),
  beforeMount() {
    this.changeLanguage(this.$route.query?.lang);
  },
  methods: {
    changeLanguage(language) {
      if (!language) return;
      i18nService.setActiveLanguage(language);
      this.activeLanguage = language;
      this.$emit('language-changed', this.languages.find(val => val.lang === language));
      this.$i18n.locale = language;
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 72px;
  border-bottom: 1px solid #6E7173;

  img {
    height: 52px;
    width: 190px;
  }
}
</style>