<template>
  <div class="top-0 bottom-0 scroll-y">
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="7000"
      controls
      indicators
      background="#ababab"
      img-height="100%"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="image in 2" :key="image">
        <template #img>
          <img class="d-block w-100 vh-100" :src="`/media/custom/auth/img-${userLanguage}-${image}.jpg`">
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
export default {
  data: () => ({
    slide: 0,
    sliding: null
  }),
  computed: {
    userLanguage() {
      return this.$i18n.locale;
    }
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.test {
  height: 100vh;
}
</style>